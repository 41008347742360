var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:[
        ("base-composite " + ((!_vm.disableDragDrop && _vm.draggable) ? 'js--drop-zone' : '')),
        { 'base-composite--border': _vm.compositeBorder },
        { 'base-composite--colored js--colored': this.searchResult && this.searchResult.group !== 'Документы' && _vm.checkConstructiveSearchResult() } ],attrs:{"data-id":_vm.composite.uuid}},[_c('div',{class:[
            'base-composite__controls',
            { 'base-composite__controls--border-bottom': _vm.compositeBorder } ]},[(_vm.usingOnDeleteWorksGroup
              ? !!this.composite.constructs
                ? !_vm.compositeChildren.length && !_vm.composite.constructs.length
                : !_vm.composite.children.length && _vm.composite.isFolder
              : _vm.showCheckbox)?_c('BaseCheckbox',{attrs:{"checked":_vm.composite === _vm.selectedComposite},on:{"change":function($event){return _vm.emitCompositeSelected(_vm.composite)}}}):_vm._e(),_c('button',{ref:"buttonLastLevelComposite",class:[
                'base-composite__view-composite-button',
                { 'base-composite__view-composite-button--dragging-zone': !_vm.disableDragDrop && _vm.draggingState },
                { 'base-composite__view-composite-button--open': _vm.composite === _vm.selectedComposite && _vm.enableSelectionColor } ],attrs:{"type":"button","id":_vm.composite.uuid,"draggable":!_vm.disableDragDrop && _vm.draggable},on:{"dragstart":function($event){return _vm.onDrag($event)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragend":_vm.onDragEnd,"dragover":function($event){$event.preventDefault();},"click":function($event){_vm.isInPopup
                ? _vm.selectCompositeToggleChildren()
                : _vm.emitCompositeSelected(_vm.composite)}}},[_vm._v(" "+_vm._s(_vm.composite.title)+" ")]),(_vm.compositeChildren && _vm.compositeChildren.length)?_c('BaseButton',{ref:"compositeButton",staticClass:"base-composite__arrow-button",attrs:{"type":"border-none"},on:{"click":function($event){_vm.isInPopup
                ? _vm.selectCompositeToggleChildren()
                : _vm.toggleCompositeChildren()}}},[_c('svg',{class:[
                    'base-composite__toggle-icon',
                    { 'base-composite__toggle-icon--open': _vm.isCompositeChildrenOpen },
                    { 'base-composite__toggle-icon--viewed': _vm.composite === _vm.selectedComposite && !_vm.isInPopup } ],attrs:{"width":"10","height":"6"}},[_c('use',{attrs:{"xlink:href":"#icon-arrow"}})])]):_vm._e()],1),(_vm.isCompositeChildrenOpen)?_c('ul',{class:[
            'base-composite__children',
            { 'base-composite__children--margined': _vm.isChildrenMarginNeeded }
        ]},_vm._l((_vm.compositeChildren),function(childComposite,index){return _c('BaseComposite',{key:("childComposite" + index),attrs:{"show-checkbox":_vm.showCheckbox,"composite":childComposite,"composite-children":_vm.getChildren(childComposite),"selected-composite":_vm.selectedComposite,"nesting-level":_vm.nextNestingLevel,"find-children-is-folder":_vm.findChildrenIsFolder,"using-on-delete-works-group":_vm.usingOnDeleteWorksGroup,"using-in-executive-docs":_vm.usingInExecutiveDocs,"is-in-popup":true,"draggable":!_vm.disableDragDrop && _vm.draggable,"enable-selection-color":_vm.enableSelectionColor},on:{"composite-selected":function($event){return _vm.emitCompositeSelected($event, _vm.composite)},"need-confirm-add-to-folder":function($event){return _vm.$emit('need-confirm-add-to-folder', $event)}}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }