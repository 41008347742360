






























































































import './scss/BaseComposite.scss';
import './scss/BaseCompositeAdaptive.scss';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { IWork, IWorkListState } from '@store/modules/work-list/interfaces/Interfaces';
import { namespace } from 'vuex-class';
import { ISearchResult } from '@store/modules/project/Interfaces';
import { ProjectActions } from '@store/modules/project/Types';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { WorkListActions } from '@store/modules/work-list/Types';

const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'BaseComposite',

    components: {
        'BaseButton': () => import('@components/buttons/BaseButton.vue'),
        'BaseCheckbox': () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    },
})
export default class BaseComposite extends Vue {
    $refs!: {
        compositeButton: HTMLFormElement,
        buttonLastLevelComposite: HTMLFormElement,
    }
    @Prop({ default: false }) usingInExecutiveDocs!: boolean;

    @NSWorkList.State((state: IWorkListState) => state.searchResultIdsChain) searchResultIdsChain!: string[];
    @NSProject.Getter('searchResult') searchResult!: ISearchResult;
    @NSDocuments.Getter('draggingState') draggingStateDocuments!: boolean;
    @NSWorkList.Getter('draggingState') draggingStateWorks!: boolean;
    @NSWorkList.Getter('pathToViewingWork') pathToViewingWork!: Set<IWork>;
    @NSProject.Action(ProjectActions.A_SET_SEARCH_RESULT) setSearchResult!: (any) => Promise<void>;
    @NSProject.Action(ProjectActions.A_SET_SEARCHING_PROCESS) setSearchingProcess!: (any) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_DRAGGING_STATE)
    setDraggingStateDocuments!: (payload: boolean) => Promise<void>;
    @NSWorkList.Action(WorkListActions.A_SET_DRAGGING_STATE)
    setDraggingStateWorks!: (payload: boolean) => Promise<void>;

    @Prop({ default: () => ({}) } ) composite!: any;
    @Prop({ default: () => ([]) } ) compositeChildren!: any;
    @Prop({ default: () => ({}) } ) selectedComposite!: any;
    @Prop({ default: 0 }) nestingLevel!: number;
    @Prop({ default: false } ) showCheckbox!: boolean;
    @Prop({ default: false } ) findChildrenIsFolder!: boolean;
    @Prop({ default: false } ) showBorderOnOpened!: boolean;
    @Prop({ default: false } ) usingOnDeleteWorksGroup!: boolean;
    @Prop({ default: false } ) isInPopup!: boolean;
    @Prop({ default: false } ) draggable!: boolean;
    @Prop({ default: false } ) enableSelectionColor!: boolean;
    @Prop({ default: false }) disableDragDrop!: boolean;

    timeForFullAnimationColored: number = 6000;
    timeOutForCheckSearchIncludeId: number = 200;
    dropped: boolean = false;
    pathToSelectedComposite: Set<any> = new Set();

    mounted() {
        this.checkCompositeIncludesSearchingIds();
    }

    @Watch('searchResultIdsChain')
    watchSearchResultIdsChain() {
        this.checkCompositeIncludesSearchingIds();
    }

    MARGIN_STARTS_ON_NESTING_LEVEL: number = 0;
    isCompositeChildrenOpen: boolean = false;

    get draggingState() {
        return this.draggingStateDocuments || this.draggingStateWorks;
    }

    get nextNestingLevel() {
        return this.nestingLevel + 1;
    }

    get isChildrenMarginNeeded() {
        return this.nestingLevel >= this.MARGIN_STARTS_ON_NESTING_LEVEL;
    }

    get compositeBorder() {
        return this.nestingLevel === 0
            && this.showBorderOnOpened
            && this.isCompositeChildrenOpen;
    }

    get openFolder(): boolean {
        let open: boolean = false;
        for (let composite of this.pathToViewingWork) {
            if (composite.uuid === this.composite.uuid) {
                open = true;
            }
        }
        return open;
    }

    onDrag(e) {
        e.dataTransfer.setData('compositeId', e.target.id);
        this.setDraggingStateDocuments(true);
        this.setDraggingStateWorks(true);
    }

    onDragEnd() {
        this.setDraggingStateDocuments(false);
        this.setDraggingStateWorks(false);
    }

    onDrop(e) {
        if (!this.dropped) {
            e.preventDefault();
            this.dropped = true;
            const parentElement = e.target.closest('.js--drop-zone');
            if (parentElement) {
                let dropElementId = e.dataTransfer.getData('documentId');
                if (e.dataTransfer.getData('compositeId') && e.dataTransfer.getData('compositeId').length) {
                    dropElementId = e.dataTransfer.getData('compositeId');
                }
                if (e.dataTransfer.getData('workId') && e.dataTransfer.getData('workId').length) {
                    dropElementId = e.dataTransfer.getData('workId');
                }
                let elementType = 'document';
                if (e.dataTransfer.getData('compositeId') && e.dataTransfer.getData('compositeId').length) {
                    elementType = 'composite';
                }
                if (e.dataTransfer.getData('workId') && e.dataTransfer.getData('workId').length) {
                    elementType = 'work';
                }
                this.$emit('need-confirm-add-to-folder', {
                    dropTargetId: parentElement.getAttribute('data-id'),
                    dropTargetTitle: this.composite.title,
                    dropElementId: dropElementId,
                    elementType: elementType,
                });
            }
            setTimeout(() => {
                this.dropped = false;
            }, 300);
        }
    }

    checkConstructiveSearchResult() {
        if (this.searchResult && this.searchResult.group) {
            return this.searchResult && this.searchResult.item && this.composite.uuid === this.searchResult.item.id;
        }
        return false;
    }

    selectCompositeToggleChildren() {
        this.emitCompositeSelected(this.composite);
        this.toggleCompositeChildren();
    }

    checkCompositeIncludesSearchingIds() {
        setTimeout(() => {
            if (
                this.searchResultIdsChain.includes(this.composite.uuid) &&
                (this.$refs.compositeButton || this.$refs.buttonLastLevelComposite)
            ) {
                if (
                    this.$refs.compositeButton
                    && this.$refs.compositeButton.$el
                    && !this.$refs.compositeButton.$el.classList.contains('base-composite__view-composite-button--open')
                ) {
                    this.$refs.compositeButton.$el.click();
                } else if (this.$refs.buttonLastLevelComposite) {
                    this.$refs.buttonLastLevelComposite.click();
                }
            }
        }, this.timeOutForCheckSearchIncludeId);
    }

    emitCompositeSelected(selectedComposite, composite?: any, pathToSelectedComposite = this.pathToSelectedComposite) {
        if (selectedComposite) {
            this.pathToSelectedComposite.add(selectedComposite);
        }
        if (composite) {
            this.pathToSelectedComposite.add(composite);
        }
        this.$emit('composite-selected', selectedComposite, composite, pathToSelectedComposite);
    }

    toggleCompositeChildren() {
        if (this.compositeChildren.length) {
            this.isCompositeChildrenOpen = !this.isCompositeChildrenOpen;
        }
    }

    getChildren(composite) {
        if (this.findChildrenIsFolder) {
            return composite.children.filter(item => item.isFolder);
        }
        return composite.children;
    }
}
